// ==================================================
// fancyBox v3.0.22
//
// Licensed GPLv3 for open source use
// or fancyBox Commercial License for commercial use
//
// http://fancyapps.com/fancybox/
// Copyright 2017 fancyApps
//
// ==================================================
!function (t, e, n, o) {
  "use strict";

  function s(t) {
    var e = t.currentTarget,
        o = t.data ? t.data.options : {},
        s = t.data ? t.data.items : [],
        i = "",
        a = 0;
    t.preventDefault(), t.stopPropagation(), n(e).attr("data-fancybox") && (i = n(e).data("fancybox")), i ? (s = s.length ? s.filter('[data-fancybox="' + i + '"]') : n("[data-fancybox=" + i + "]"), a = s.index(e)) : s = [e], n.fancybox.open(s, o, a);
  }

  if (!n) return o;

  var i = {
    speed: 330,
    loop: !0,
    opacity: "auto",
    margin: [44, 0],
    gutter: 30,
    infobar: !0,
    buttons: !0,
    slideShow: !0,
    fullScreen: !0,
    thumbs: !0,
    closeBtn: !0,
    smallBtn: "auto",
    image: {
      preload: "auto",
      protect: !1
    },
    ajax: {
      settings: {
        data: {
          fancybox: !0
        }
      }
    },
    iframe: {
      tpl: '<iframe id="fancybox-frame{rnd}" name="fancybox-frame{rnd}" class="fancybox-iframe" frameborder="0" vspace="0" hspace="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen allowtransparency="true" src=""></iframe>',
      preload: !0,
      scrolling: "no",
      css: {}
    },
    baseClass: "",
    slideClass: "",
    baseTpl: '<div class="fancybox-container" role="dialog" tabindex="-1"><div class="fancybox-bg"></div><div class="fancybox-controls"><div class="fancybox-infobar"><button data-fancybox-previous class="fancybox-button fancybox-button--left" title="Previous"></button><div class="fancybox-infobar__body"><span class="js-fancybox-index"></span>&nbsp;/&nbsp;<span class="js-fancybox-count"></span></div><button data-fancybox-next class="fancybox-button fancybox-button--right" title="Next"></button></div><div class="fancybox-buttons"><button data-fancybox-close class="fancybox-button fancybox-button--close" title="Close (Esc)"></button></div></div><div class="fancybox-slider-wrap"><div class="fancybox-slider"></div></div><div class="fancybox-caption-wrap"><div class="fancybox-caption"></div></div></div>',
    spinnerTpl: '<div class="fancybox-loading"></div>',
    errorTpl: '<div class="fancybox-error"><p>The requested content cannot be loaded. <br /> Please try again later.<p></div>',
    closeTpl: '<button data-fancybox-close class="fancybox-close-small">×</button>',
    parentEl: "body",
    touch: !0,
    keyboard: !0,
    focus: !0,
    closeClickOutside: !0,
    beforeLoad: n.noop,
    afterLoad: n.noop,
    beforeMove: n.noop,
    afterMove: n.noop,
    onComplete: n.noop,
    onInit: n.noop,
    beforeClose: n.noop,
    afterClose: n.noop,
    onActivate: n.noop,
    onDeactivate: n.noop
  },
      a = n(t),
      r = n(e),
      c = 0,
      l = function l(t) {
    return t && t.hasOwnProperty && t instanceof n;
  },
      u = function () {
    return t.requestAnimationFrame || t.webkitRequestAnimationFrame || t.mozRequestAnimationFrame || function (e) {
      t.setTimeout(e, 1e3 / 60);
    };
  }(),
      d = function d(o) {
    var s;
    return "function" == typeof n && o instanceof n && (o = o[0]), s = o.getBoundingClientRect(), s.bottom > 0 && s.right > 0 && s.left < (t.innerWidth || e.documentElement.clientWidth) && s.top < (t.innerHeight || e.documentElement.clientHeight);
  },
      p = function p(t, o, s) {
    var a = this;
    a.opts = n.extend(!0, {
      index: s
    }, i, o || {}), a.id = a.opts.id || ++c, a.group = [], a.currIndex = parseInt(a.opts.index, 10) || 0, a.prevIndex = null, a.prevPos = null, a.currPos = 0, a.firstRun = null, a.createGroup(t), a.group.length && (a.$lastFocus = n(e.activeElement), a.elems = {}, a.slides = {}, a.init(t));
  };

  n.extend(p.prototype, {
    init: function init() {
      var t,
          e,
          o = this;
      o.scrollTop = a.scrollTop(), o.scrollLeft = a.scrollLeft(), n.fancybox.isTouch || n("html").hasClass("fancybox-enabled") || (t = n("body").width(), n("html").addClass("fancybox-enabled"), t = n("body").width() - t, t > 1 && n('<style id="fancybox-noscroll" type="text/css">').html(".compensate-for-scrollbar, .fancybox-enabled body { margin-right: " + t + "px; }").appendTo("head")), e = n(o.opts.baseTpl).attr("id", "fancybox-container-" + o.id).data("FancyBox", o).addClass(o.opts.baseClass).hide().prependTo(o.opts.parentEl), o.$refs = {
        container: e,
        bg: e.find(".fancybox-bg"),
        controls: e.find(".fancybox-controls"),
        buttons: e.find(".fancybox-buttons"),
        slider_wrap: e.find(".fancybox-slider-wrap"),
        slider: e.find(".fancybox-slider"),
        caption: e.find(".fancybox-caption")
      }, o.trigger("onInit"), o.activate(), o.current || o.jumpTo(o.currIndex);
    },
    createGroup: function createGroup(t) {
      var e = this,
          s = n.makeArray(t);
      n.each(s, function (t, s) {
        var i,
            a,
            r,
            c,
            l,
            u = {},
            d = {};
        n.isPlainObject(s) ? (u = s, d = s.opts || {}) : "object" === n.type(s) && n(s).length ? (i = n(s), a = i.data(), d = "options" in a ? a.options : {}, d = "object" === n.type(d) ? d : {}, u.type = "type" in a ? a.type : d.type, u.src = "src" in a ? a.src : d.src || i.attr("href"), d.width = "width" in a ? a.width : d.width, d.height = "height" in a ? a.height : d.height, d.thumb = "thumb" in a ? a.thumb : d.thumb, d.caption = "caption" in a ? a.caption : d.caption || i.attr("title"), d.selector = "selector" in a ? a.selector : d.selector, d.$orig = i) : u = {
          type: "html",
          content: s + ""
        }, u.opts = n.extend(!0, {}, e.opts, d), r = u.type, c = u.src || "", r || (u.content ? r = "html" : c.match(/(^data:image\/[a-z0-9+\/=]*,)|(\.(jp(e|g|eg)|gif|png|bmp|webp|svg|ico)((\?|#).*)?$)/i) ? r = "image" : c.match(/\.(pdf)((\?|#).*)?$/i) ? r = "pdf" : "#" === c.charAt(0) && (r = "inline"), u.type = r), u.index = e.group.length, u.opts.$orig && !u.opts.$orig.length && delete u.opts.$orig, !u.opts.$thumb && u.opts.$orig && (u.opts.$thumb = u.opts.$orig.find("img:first")), u.opts.$thumb && !u.opts.$thumb.length && delete u.opts.$thumb, "function" === n.type(e.opts.caption) ? u.opts.caption = e.opts.caption.apply(s, [e, u]) : u.opts.caption = u.opts.caption === o ? "" : u.opts.caption + "", "ajax" === r && (l = c.split(/\s+/, 2), l.length > 1 && (u.src = l.shift(), u.opts.selector = l.shift())), "auto" == u.opts.smallBtn && (n.inArray(r, ["html", "inline", "ajax"]) > -1 ? (u.opts.buttons = !1, u.opts.smallBtn = !0) : u.opts.smallBtn = !1), "pdf" === r && (u.type = "iframe", u.opts.closeBtn = !0, u.opts.smallBtn = !1, u.opts.iframe.preload = !1), u.opts.modal && n.extend(!0, u.opts, {
          infobar: 0,
          buttons: 0,
          keyboard: 0,
          slideShow: 0,
          fullScreen: 0,
          closeClickOutside: 0
        }), e.group.push(u);
      });
    },
    addEvents: function addEvents() {
      var o = this,
          s = function s() {
        a.scrollTop(o.scrollTop).scrollLeft(o.scrollLeft), o.$refs.slider_wrap.show(), o.update();
      };

      o.removeEvents(), o.$refs.container.on("click.fb-close", "[data-fancybox-close]", function (t) {
        t.stopPropagation(), t.preventDefault(), o.close(t);
      }).on("click.fb-previous", "[data-fancybox-previous]", function (t) {
        t.stopPropagation(), t.preventDefault(), o.previous();
      }).on("click.fb-next", "[data-fancybox-next]", function (t) {
        t.stopPropagation(), t.preventDefault(), o.next();
      }), n(t).on("orientationchange.fb resize.fb", function (t) {
        u(function () {
          "orientationchange" == t.type ? (o.$refs.slider_wrap.hide(), u(s)) : s();
        });
      }), r.on("focusin.fb", function (t) {
        var e;
        n.fancybox && (e = n.fancybox.getInstance(), !e || n(t.target).hasClass("fancybox-container") || n.contains(e.$refs.container[0], t.target) || (t.stopPropagation(), e.focus()));
      }), n(e).on("keydown.fb", function (t) {
        var e = o.current,
            s = t.keyCode || t.which;

        if (e && e.opts.keyboard && !n(t.target).is("input") && !n(t.target).is("textarea")) {
          if (8 === s || 27 === s) return t.preventDefault(), void o.close();

          switch (s) {
            case 37:
            case 38:
              t.preventDefault(), o.previous();
              break;

            case 39:
            case 40:
              t.preventDefault(), o.next();
              break;

            case 80:
            case 32:
              t.preventDefault(), o.SlideShow && (t.preventDefault(), o.SlideShow.toggle());
              break;

            case 70:
              o.FullScreen && (t.preventDefault(), o.FullScreen.toggle());
              break;

            case 71:
              o.Thumbs && (t.preventDefault(), o.Thumbs.toggle());
          }
        }
      });
    },
    removeEvents: function removeEvents() {
      a.off("scroll.fb resize.fb orientationchange.fb"), r.off("keydown.fb focusin.fb click.fb-close"), this.$refs.container.off("click.fb-close click.fb-previous click.fb-next");
    },
    previous: function previous(t) {
      this.jumpTo(this.currIndex - 1, t);
    },
    next: function next(t) {
      this.jumpTo(this.currIndex + 1, t);
    },
    jumpTo: function jumpTo(t, e) {
      var n,
          s,
          i,
          a,
          r = this;

      if (n = r.firstRun = null === r.firstRun, s = i = t = parseInt(t, 10), a = !!r.current && r.current.opts.loop, !r.isAnimating && (s != r.currIndex || n)) {
        if (r.group.length > 1 && a) s %= r.group.length, s = s < 0 ? r.group.length + s : s, 2 == r.group.length ? i = t - r.currIndex + r.currPos : (i = s - r.currIndex + r.currPos, Math.abs(r.currPos - (i + r.group.length)) < Math.abs(r.currPos - i) ? i += r.group.length : Math.abs(r.currPos - (i - r.group.length)) < Math.abs(r.currPos - i) && (i -= r.group.length));else if (!r.group[s]) return void r.update(!1, !1, e);
        r.current && (r.current.$slide.removeClass("fancybox-slide--current fancybox-slide--complete"), r.updateSlide(r.current, !0)), r.prevIndex = r.currIndex, r.prevPos = r.currPos, r.currIndex = s, r.currPos = i, r.createSlide(i), r.group.length > 1 && ((r.opts.loop || i - 1 >= 0) && r.createSlide(i - 1), (r.opts.loop || i + 1 < r.group.length) && r.createSlide(i + 1)), r.current = r.slides[i], r.current.isMoved = !1, r.current.isComplete = !1, e = parseInt(e === o ? 1.5 * r.current.opts.speed : e, 10), r.trigger("beforeMove"), r.updateControls(), n && (r.current.$slide.addClass("fancybox-slide--current"), r.$refs.container.show(), u(function () {
          r.$refs.bg.css("transition-duration", r.current.opts.speed + "ms"), r.$refs.container.addClass("fancybox-container--ready");
        })), r.update(!0, !1, n ? 0 : e, function () {
          r.afterMove();
        }), r.loadSlide(r.current), n && r.current.$ghost || r.preload();
      }
    },
    createSlide: function createSlide(t) {
      var e,
          o,
          s = this;
      o = t % s.group.length, o = o < 0 ? s.group.length + o : o, !s.slides[t] && s.group[o] && (e = n('<div class="fancybox-slide"></div>').appendTo(s.$refs.slider), s.slides[t] = n.extend(!0, {}, s.group[o], {
        pos: t,
        $slide: e,
        isMoved: !1,
        isLoaded: !1
      }));
    },
    zoomInOut: function zoomInOut(t, e, o) {
      var s,
          i,
          a,
          r = this,
          c = r.current,
          l = c.$placeholder,
          p = c.opts.opacity,
          h = c.opts.$thumb,
          f = h ? h.offset() : 0,
          g = c.$slide.offset();
      return !!(l && c.isMoved && f && d(h)) && !("In" === t && !r.firstRun) && (n.fancybox.stop(l), r.isAnimating = !0, s = {
        top: f.top - g.top + parseFloat(h.css("border-top-width") || 0),
        left: f.left - g.left + parseFloat(h.css("border-left-width") || 0),
        width: h.width(),
        height: h.height(),
        scaleX: 1,
        scaleY: 1
      }, "auto" == p && (p = Math.abs(c.width / c.height - s.width / s.height) > .1), "In" === t ? (i = s, a = r.getFitPos(c), a.scaleX = a.width / i.width, a.scaleY = a.height / i.height, p && (i.opacity = .1, a.opacity = 1)) : (i = n.fancybox.getTranslate(l), a = s, c.$ghost && (c.$ghost.show(), c.$image && c.$image.remove()), i.scaleX = i.width / a.width, i.scaleY = i.height / a.height, i.width = a.width, i.height = a.height, p && (a.opacity = 0)), r.updateCursor(a.width, a.height), delete a.width, delete a.height, n.fancybox.setTranslate(l, i), l.show(), r.trigger("beforeZoom" + t), u(function () {
        l.css("transition", "all " + e + "ms"), n.fancybox.setTranslate(l, a), setTimeout(function () {
          var e;
          l.css("transition", "none"), e = n.fancybox.getTranslate(l), e.scaleX = 1, e.scaleY = 1, n.fancybox.setTranslate(l, e), r.trigger("afterZoom" + t), o.apply(r), r.isAnimating = !1;
        }, e);
      }), !0);
    },
    canPan: function canPan() {
      var t = this,
          e = t.current,
          n = e.$placeholder,
          o = !1;
      return n && (o = t.getFitPos(e), o = Math.abs(n.width() - o.width) > 1 || Math.abs(n.height() - o.height) > 1), o;
    },
    isScaledDown: function isScaledDown() {
      var t = this,
          e = t.current,
          o = e.$placeholder,
          s = !1;
      return o && (s = n.fancybox.getTranslate(o), s = s.width < e.width || s.height < e.height), s;
    },
    scaleToActual: function scaleToActual(t, e, s) {
      var i,
          a,
          r,
          c,
          l,
          u = this,
          d = u.current,
          p = d.$placeholder,
          h = parseInt(d.$slide.width(), 10),
          f = parseInt(d.$slide.height(), 10),
          g = d.width,
          b = d.height;
      p && (u.isAnimating = !0, t = t === o ? .5 * h : t, e = e === o ? .5 * f : e, i = n.fancybox.getTranslate(p), c = g / i.width, l = b / i.height, a = .5 * h - .5 * g, r = .5 * f - .5 * b, g > h && (a = i.left * c - (t * c - t), a > 0 && (a = 0), a < h - g && (a = h - g)), b > f && (r = i.top * l - (e * l - e), r > 0 && (r = 0), r < f - b && (r = f - b)), u.updateCursor(g, b), n.fancybox.animate(p, null, {
        top: r,
        left: a,
        scaleX: c,
        scaleY: l
      }, s || d.opts.speed, function () {
        u.isAnimating = !1;
      }));
    },
    scaleToFit: function scaleToFit(t) {
      var e,
          o = this,
          s = o.current,
          i = s.$placeholder;
      i && (o.isAnimating = !0, e = o.getFitPos(s), o.updateCursor(e.width, e.height), n.fancybox.animate(i, null, {
        top: e.top,
        left: e.left,
        scaleX: e.width / i.width(),
        scaleY: e.height / i.height()
      }, t || s.opts.speed, function () {
        o.isAnimating = !1;
      }));
    },
    getFitPos: function getFitPos(t) {
      var e,
          o,
          s,
          i,
          r,
          c,
          l,
          u = t.$placeholder || t.$content,
          d = t.width,
          p = t.height,
          h = t.opts.margin;
      return !(!u || !u.length || !d && !p) && ("number" === n.type(h) && (h = [h, h]), 2 == h.length && (h = [h[0], h[1], h[0], h[1]]), a.width() < 800 && (h = [0, 0, 0, 0]), e = parseInt(t.$slide.width(), 10) - (h[1] + h[3]), o = parseInt(t.$slide.height(), 10) - (h[0] + h[2]), s = Math.min(1, e / d, o / p), c = Math.floor(s * d), l = Math.floor(s * p), i = Math.floor(.5 * (o - l)) + h[0], r = Math.floor(.5 * (e - c)) + h[3], {
        top: i,
        left: r,
        width: c,
        height: l
      });
    },
    update: function update(t, e, o, s) {
      var i = this,
          a = i.current.pos * Math.floor(i.current.$slide.width()) * -1 - i.current.pos * i.current.opts.gutter;
      i.isAnimating !== !0 && (o = parseInt(o, 10) || 0, n.fancybox.stop(i.$refs.slider), t === !1 ? i.updateSlide(i.current, e) : n.each(i.slides, function (t, n) {
        i.updateSlide(n, e);
      }), o ? n.fancybox.animate(i.$refs.slider, null, {
        top: 0,
        left: a
      }, o, function () {
        i.current.isMoved = !0, "function" === n.type(s) && s.apply(i);
      }) : (n.fancybox.setTranslate(i.$refs.slider, {
        top: 0,
        left: a
      }), i.current.isMoved = !0, "function" === n.type(s) && s.apply(i)));
    },
    updateSlide: function updateSlide(t, e) {
      var o = this,
          s = t.$placeholder;
      t = t || o.current, t && !o.isClosing && (n.fancybox.setTranslate(t.$slide, {
        top: 0,
        left: t.pos * Math.floor(t.$slide.width()) + t.pos * t.opts.gutter
      }), e !== !1 && s && (n.fancybox.setTranslate(s, o.getFitPos(t)), t.pos === o.currPos && o.updateCursor()), t.$slide.trigger("refresh"), o.trigger("onUpdate", t));
    },
    updateCursor: function updateCursor(t, e) {
      var n,
          s = this,
          i = s.$refs.container.removeClass("fancybox-controls--canzoomIn fancybox-controls--canzoomOut fancybox-controls--canGrab");
      !s.isClosing && s.opts.touch && (n = t !== o && e !== o ? t < s.current.width && e < s.current.height : s.isScaledDown(), n ? i.addClass("fancybox-controls--canzoomIn") : s.group.length < 2 ? i.addClass("fancybox-controls--canzoomOut") : i.addClass("fancybox-controls--canGrab"));
    },
    loadSlide: function loadSlide(t) {
      var e,
          o,
          s,
          i = this;

      if (t && !t.isLoaded && !t.isLoading) {
        switch (t.isLoading = !0, i.trigger("beforeLoad", t), e = t.type, o = t.$slide, o.unbind("refresh").trigger("onReset").addClass("fancybox-slide--" + (e || "unknown")).addClass(t.opts.slideClass), e) {
          case "image":
            i.setImage(t);
            break;

          case "iframe":
            i.setIframe(t);
            break;

          case "html":
            i.setContent(t, t.content);
            break;

          case "inline":
            n(t.src).length ? i.setContent(t, n(t.src)) : i.setError(t);
            break;

          case "ajax":
            i.showLoading(t), s = n.ajax(n.extend({}, t.opts.ajax.settings, {
              url: t.src,
              success: function success(e, n) {
                "success" === n && i.setContent(t, e);
              },
              error: function error(e, n) {
                e && "abort" !== n && i.setError(t);
              }
            })), o.one("onReset", function () {
              s.abort();
            });
            break;

          default:
            i.setError(t);
        }

        return !0;
      }
    },
    setImage: function setImage(t) {
      var e = this;
      return t.isLoaded && !t.hasError ? void e.afterLoad(t) : (t.$placeholder = n('<div class="fancybox-placeholder"></div>').hide().appendTo(t.$slide), void (t.opts.preload !== !1 && t.opts.width && t.opts.height && (t.opts.thumb || t.opts.$thumb) ? (t.width = t.opts.width, t.height = t.opts.height, t.$ghost = n("<img />").one("load error", function () {
        e.isClosing || (n("<img/>")[0].src = t.src, e.revealImage(t, function () {
          e.setBigImage(t), e.firstRun && t.index === e.currIndex && e.preload();
        }));
      }).addClass("fancybox-image").appendTo(t.$placeholder).attr("src", t.opts.thumb || t.opts.$thumb.attr("src"))) : e.setBigImage(t)));
    },
    setBigImage: function setBigImage(t) {
      var e = this,
          o = n("<img />");
      t.opts.image.protect && n('<div class="fancybox-spaceball"></div>').appendTo(t.$placeholder), t.$image = o.one("error", function () {
        e.setError(t);
      }).one("load", function () {
        e.isClosing || (t.width = this.naturalWidth, t.height = this.naturalHeight, e.afterLoad(t), t.$ghost && (t.timouts = setTimeout(function () {
          t.$ghost.hide();
        }, 350)));
      }).addClass("fancybox-image").appendTo(t.$placeholder).attr("src", t.src), o[0].complete ? o.trigger("load") : o[0].error ? o.trigger("error") : t.timouts = setTimeout(function () {
        o[0].complete || t.hasError || e.showLoading(t);
      }, 150);
    },
    revealImage: function revealImage(t, e) {
      var o = this;
      return e = e || n.noop, "image" !== t.type || t.hasError || t.isRevealed === !0 ? void e.apply(o) : (t.isRevealed = !0, void (t.pos === o.currPos && o.zoomInOut("In", t.opts.speed, e) || (t.$ghost && !t.isLoaded && o.updateSlide(t, !0), t.pos === o.currPos ? n.fancybox.animate(t.$placeholder, {
        opacity: 0
      }, {
        opacity: 1
      }, 300, e) : t.$placeholder.show(), e.apply(o))));
    },
    setIframe: function setIframe(t) {
      var e,
          s = this,
          i = t.opts.iframe,
          a = t.$slide;
      t.$content = n('<div class="fancybox-content"></div>').css(i.css).appendTo(a), e = n(i.tpl.replace(/\{rnd\}/g, new Date().getTime())).attr("scrolling", n.fancybox.isTouch ? "auto" : i.scrolling).appendTo(t.$content), i.preload ? (t.$content.addClass("fancybox-tmp"), s.showLoading(t), e.on("load.fb error.fb", function (e) {
        this.isReady = 1, t.$slide.trigger("refresh"), s.afterLoad(t);
      }), a.on("refresh.fb", function () {
        var n,
            s,
            a,
            r,
            c,
            l = t.$content;

        if (1 === e[0].isReady) {
          try {
            n = e.contents(), s = n.find("body");
          } catch (t) {}

          s && s.length && (i.css.width === o || i.css.height === o) && (a = e[0].contentWindow.document.documentElement.scrollWidth, r = Math.ceil(s.outerWidth(!0) + (l.width() - a)), c = Math.ceil(s.outerHeight(!0)), l.css({
            width: i.css.width === o ? r + (l.outerWidth() - l.innerWidth()) : i.css.width,
            height: i.css.height === o ? c + (l.outerHeight() - l.innerHeight()) : i.css.height
          })), l.removeClass("fancybox-tmp");
        }
      })) : this.afterLoad(t), e.attr("src", t.src), t.opts.smallBtn && t.$content.prepend(t.opts.closeTpl), a.one("onReset", function () {
        try {
          n(this).find("iframe").hide().attr("src", "//about:blank");
        } catch (t) {}

        n(this).empty(), t.isLoaded = !1;
      });
    },
    setContent: function setContent(t, e) {
      var o = this;
      o.isClosing || (o.hideLoading(t), t.$slide.empty(), l(e) && e.parent().length ? (e.data("placeholder") && e.parents(".fancybox-slide").trigger("onReset"), e.data({
        placeholder: n("<div></div>").hide().insertAfter(e)
      }).css("display", "inline-block")) : ("string" === n.type(e) && (e = n("<div>").append(e).contents(), 3 === e[0].nodeType && (e = n("<div>").html(e))), t.opts.selector && (e = n("<div>").html(e).find(t.opts.selector))), t.$slide.one("onReset", function () {
        var o = l(e) ? e.data("placeholder") : 0;
        o && (e.hide().replaceAll(o), e.data("placeholder", null)), t.hasError || (n(this).empty(), t.isLoaded = !1);
      }), t.$content = n(e).appendTo(t.$slide), t.opts.smallBtn === !0 && t.$content.find(".fancybox-close-small").remove().end().eq(0).append(t.opts.closeTpl), this.afterLoad(t));
    },
    setError: function setError(t) {
      t.hasError = !0, this.setContent(t, t.opts.errorTpl);
    },
    showLoading: function showLoading(t) {
      var e = this;
      t = t || e.current, t && !t.$spinner && (t.$spinner = n(e.opts.spinnerTpl).appendTo(t.$slide));
    },
    hideLoading: function hideLoading(t) {
      var e = this;
      t = t || e.current, t && t.$spinner && (t.$spinner.remove(), delete t.$spinner);
    },
    afterMove: function afterMove() {
      var t = this,
          e = t.current,
          o = {};
      e && (e.$slide.siblings().trigger("onReset"), n.each(t.slides, function (e, n) {
        n.pos >= t.currPos - 1 && n.pos <= t.currPos + 1 ? o[n.pos] = n : n && n.$slide.remove();
      }), t.slides = o, t.trigger("afterMove"), e.isLoaded && t.complete());
    },
    afterLoad: function afterLoad(t) {
      var e = this;
      e.isClosing || (t.isLoading = !1, t.isLoaded = !0, e.trigger("afterLoad", t), e.hideLoading(t), t.$ghost || e.updateSlide(t, !0), t.index === e.currIndex && t.isMoved ? e.complete() : t.$ghost || e.revealImage(t));
    },
    complete: function complete() {
      var t = this,
          e = t.current;
      t.revealImage(e, function () {
        e.isComplete = !0, e.$slide.addClass("fancybox-slide--complete"), t.updateCursor(), t.trigger("onComplete"), e.opts.focus && t.focus();
      });
    },
    preload: function preload() {
      var t,
          e,
          n = this;
      n.group.length < 2 || (t = n.slides[n.currPos + 1], e = n.slides[n.currPos - 1], t && "image" === t.type && n.loadSlide(t), e && "image" === e.type && n.loadSlide(e));
    },
    focus: function focus() {
      var t = this.current && this.current.isComplete ? this.current.$slide.find('button,:input,[tabindex],a:not(".disabled")').filter(":visible:first") : null;
      t && t.length || (t = this.$refs.container), t.focus(), this.$refs.slider_wrap.scrollLeft(0), this.current && this.current.$slide.scrollTop(0);
    },
    activate: function activate() {
      var t = this;
      n(".fancybox-container").each(function () {
        var e = n(this).data("FancyBox");
        e && e.uid !== t.uid && !e.isClosing && e.trigger("onDeactivate");
      }), t.current && (t.$refs.container.index() > 0 && t.$refs.container.prependTo(e.body), t.updateControls()), t.trigger("onActivate"), t.addEvents();
    },
    close: function close(t) {
      var e = this,
          o = e.current,
          s = o.opts.speed,
          i = n.proxy(function () {
        e.cleanUp(t);
      }, this);
      return !e.isAnimating && !e.isClosing && (e.isClosing = !0, o.timouts && clearTimeout(o.timouts), t !== !0 && n.fancybox.stop(e.$refs.slider), e.$refs.container.removeClass("fancybox-container--active").addClass("fancybox-container--closing"), o.$slide.removeClass("fancybox-slide--complete").siblings().remove(), o.isMoved || o.$slide.css("overflow", "visible"), e.removeEvents(), e.hideLoading(o), e.hideControls(), e.updateCursor(), e.trigger("beforeClose", o, t), e.$refs.bg.css("transition-duration", s + "ms"), this.$refs.container.removeClass("fancybox-container--ready"), void (t === !0 ? setTimeout(i, s) : e.zoomInOut("Out", s, i) || n.fancybox.animate(e.$refs.container, null, {
        opacity: 0
      }, s, "easeInSine", i)));
    },
    cleanUp: function cleanUp(t) {
      var e,
          o = this;
      o.$refs.slider.children().trigger("onReset"), o.$refs.container.empty().remove(), o.current = null, o.trigger("afterClose", t), e = n.fancybox.getInstance(), e ? e.activate() : (n("html").removeClass("fancybox-enabled"), n("#fancybox-noscroll").remove()), o.$lastFocus && o.$lastFocus.focus(), a.scrollTop(o.scrollTop).scrollLeft(o.scrollLeft);
    },
    trigger: function trigger(t, e) {
      var o = Array.prototype.slice.call(arguments, 1),
          s = this,
          i = e && e.opts ? e : s.current;
      i ? o.unshift(i) : i = s, o.unshift(s), n.isFunction(i.opts[t]) && i.opts[t].apply(i, o), s.$refs.container.trigger(t + ".fb", o);
    },
    toggleControls: function toggleControls(t) {
      this.isHiddenControls ? this.updateControls(t) : this.hideControls();
    },
    hideControls: function hideControls() {
      this.isHiddenControls = !0, this.$refs.container.removeClass("fancybox-show-controls"), this.$refs.container.removeClass("fancybox-show-caption");
    },
    updateControls: function updateControls(t) {
      var e = this,
          o = e.$refs.container,
          s = e.$refs.caption,
          i = e.current,
          a = i.index,
          r = i.opts,
          c = r.caption;
      this.isHiddenControls && t !== !0 || (this.isHiddenControls = !1, e.$refs.container.addClass("fancybox-show-controls"), o.toggleClass("fancybox-show-infobar", !!r.infobar && e.group.length > 1).toggleClass("fancybox-show-buttons", !!r.buttons).toggleClass("fancybox-is-modal", !!r.modal), n(".fancybox-button--left", o).toggleClass("fancybox-button--disabled", !r.loop && a <= 0), n(".fancybox-button--right", o).toggleClass("fancybox-button--disabled", !r.loop && a >= e.group.length - 1), n(".fancybox-button--play", o).toggle(!!(r.slideShow && e.group.length > 1)), n(".fancybox-button--close", o).toggle(!!r.closeBtn), n(".js-fancybox-count", o).html(e.group.length), n(".js-fancybox-index", o).html(a + 1), i.$slide.trigger("refresh"), s && s.empty(), c && c.length ? (s.html(c), this.$refs.container.addClass("fancybox-show-caption "), e.$caption = s) : this.$refs.container.removeClass("fancybox-show-caption"));
    }
  }), n.fancybox = {
    version: "3.0.22",
    defaults: i,
    getInstance: function getInstance(t) {
      var e = n('.fancybox-container:not(".fancybox-container--closing"):first').data("FancyBox"),
          o = Array.prototype.slice.call(arguments, 1);
      return e instanceof p && ("string" === n.type(t) ? e[t].apply(e, o) : "function" === n.type(t) && t.apply(e, o), e);
    },
    open: function open(t, e, n) {
      return new p(t, e, n);
    },
    close: function close(t) {
      var e = this.getInstance();
      e && (e.close(), t === !0 && this.close());
    },
    isTouch: e.createTouch !== o && /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent),
    use3d: function () {
      var n = e.createElement("div");
      return t.getComputedStyle(n).getPropertyValue("transform") && !(e.documentMode && e.documentMode <= 11);
    }(),
    getTranslate: function getTranslate(t) {
      var e, n;
      return !(!t || !t.length) && (e = t.get(0).getBoundingClientRect(), n = t.eq(0).css("transform"), n && n.indexOf("matrix") !== -1 ? (n = n.split("(")[1], n = n.split(")")[0], n = n.split(",")) : n = [], n.length ? (n = n.length > 10 ? [n[13], n[12], n[0], n[5]] : [n[5], n[4], n[0], n[3]], n = n.map(parseFloat)) : n = [0, 0, 1, 1], {
        top: n[0],
        left: n[1],
        scaleX: n[2],
        scaleY: n[3],
        opacity: parseFloat(t.css("opacity")),
        width: e.width,
        height: e.height
      });
    },
    setTranslate: function setTranslate(t, e) {
      var n = "",
          s = {};
      if (t && e) return e.left === o && e.top === o || (n = (e.left === o ? t.position().top : e.left) + "px, " + (e.top === o ? t.position().top : e.top) + "px", n = this.use3d ? "translate3d(" + n + ", 0px)" : "translate(" + n + ")"), e.scaleX !== o && e.scaleY !== o && (n = (n.length ? n + " " : "") + "scale(" + e.scaleX + ", " + e.scaleY + ")"), n.length && (s.transform = n), e.opacity !== o && (s.opacity = e.opacity), e.width !== o && (s.width = e.width), e.height !== o && (s.height = e.height), t.css(s);
    },
    easing: {
      easeOutCubic: function easeOutCubic(t, e, n, o) {
        return n * ((t = t / o - 1) * t * t + 1) + e;
      },
      easeInCubic: function easeInCubic(t, e, n, o) {
        return n * (t /= o) * t * t + e;
      },
      easeOutSine: function easeOutSine(t, e, n, o) {
        return n * Math.sin(t / o * (Math.PI / 2)) + e;
      },
      easeInSine: function easeInSine(t, e, n, o) {
        return -n * Math.cos(t / o * (Math.PI / 2)) + n + e;
      }
    },
    stop: function stop(t) {
      t.removeData("animateID");
    },
    animate: function animate(t, e, s, i, a, r) {
      var c,
          l,
          d,
          p = this,
          h = null,
          f = 0,
          g = function g(n) {
        if (c = [], l = 0, t.length && t.data("animateID") === d) {
          if (n = n || Date.now(), h && (l = n - h), h = n, f += l, f >= i) return s.scaleX !== o && s.scaleY !== o && e.width !== o && e.height !== o && (s.width = e.width * s.scaleX, s.height = e.height * s.scaleY, s.scaleX = 1, s.scaleY = 1), p.setTranslate(t, s), void r();

          for (var b in s) {
            s.hasOwnProperty(b) && e[b] !== o && (e[b] == s[b] ? c[b] = s[b] : c[b] = p.easing[a](f, e[b], s[b] - e[b], i));
          }

          p.setTranslate(t, c), u(g);
        }
      };

      return p.animateID = d = p.animateID === o ? 1 : p.animateID + 1, t.data("animateID", d), r === o && "function" == n.type(a) && (r = a, a = o), a || (a = "easeOutCubic"), r = r || n.noop, i ? (e ? this.setTranslate(t, e) : e = this.getTranslate(t), t.show(), void u(g)) : (this.setTranslate(t, s), void r());
    }
  }, n.fn.fancybox = function (t) {
    return this.off("click.fb-start").on("click.fb-start", {
      items: this,
      options: t || {}
    }, s), this;
  }, n(e).on("click.fb-start", "[data-fancybox]", s);
}(window, document, window.jQuery), function (t) {
  "use strict";

  var e = function e(_e, n, o) {
    if (_e) return o = o || "", "object" === t.type(o) && (o = t.param(o, !0)), t.each(n, function (t, n) {
      _e = _e.replace("$" + t, n || "");
    }), o.length && (_e += (_e.indexOf("?") > 0 ? "&" : "?") + o), _e;
  },
      n = {
    youtube: {
      matcher: /(youtube\.com|youtu\.be|youtube\-nocookie\.com)\/(watch\?(.*&)?v=|v\/|u\/|embed\/?)?(videoseries\?list=(.*)|[\w-]{11}|\?listType=(.*)&list=(.*))(.*)/i,
      params: {
        autoplay: 1,
        autohide: 1,
        fs: 1,
        rel: 0,
        hd: 1,
        wmode: "transparent",
        enablejsapi: 1,
        html5: 1
      },
      paramPlace: 8,
      type: "iframe",
      url: "//www.youtube.com/embed/$4",
      thumb: "//img.youtube.com/vi/$4/hqdefault.jpg"
    },
    vimeo: {
      matcher: /((player\.)?vimeo(pro)?\.com)\/(video\/)?([\d]+)?(\?(.*))?/,
      params: {
        autoplay: 1,
        hd: 1,
        show_title: 1,
        show_byline: 1,
        show_portrait: 0,
        fullscreen: 1,
        api: 1
      },
      paramPlace: 7,
      type: "iframe",
      url: "//player.vimeo.com/video/$5"
    },
    metacafe: {
      matcher: /metacafe.com\/watch\/(\d+)\/(.*)?/,
      type: "iframe",
      url: "//www.metacafe.com/embed/$1/?ap=1"
    },
    dailymotion: {
      matcher: /dailymotion.com\/video\/(.*)\/?(.*)/,
      params: {
        additionalInfos: 0,
        autoStart: 1
      },
      type: "iframe",
      url: "//www.dailymotion.com/embed/video/$1"
    },
    vine: {
      matcher: /vine.co\/v\/([a-zA-Z0-9\?\=\-]+)/,
      type: "iframe",
      url: "//vine.co/v/$1/embed/simple"
    },
    instagram: {
      matcher: /(instagr\.am|instagram\.com)\/p\/([a-zA-Z0-9_\-]+)\/?/i,
      type: "image",
      url: "//$1/p/$2/media/?size=l"
    },
    google_maps: {
      matcher: /(maps\.)?google\.([a-z]{2,3}(\.[a-z]{2})?)\/(((maps\/(place\/(.*)\/)?\@(.*),(\d+.?\d+?)z))|(\?ll=))(.*)?/i,
      type: "iframe",
      url: function url(t) {
        return "//maps.google." + t[2] + "/?ll=" + (t[9] ? t[9] + "&z=" + Math.floor(t[10]) + (t[12] ? t[12].replace(/^\//, "&") : "") : t[12]) + "&output=" + (t[12] && t[12].indexOf("layer=c") > 0 ? "svembed" : "embed");
      }
    }
  };

  t(document).on("onInit.fb", function (o, s) {
    t.each(s.group, function (o, s) {
      var i,
          a,
          r,
          c,
          l,
          u,
          d,
          p = s.src || "",
          h = !1;
      s.type || (t.each(n, function (n, o) {
        if (a = p.match(o.matcher), l = {}, d = n, a) {
          if (h = o.type, o.paramPlace && a[o.paramPlace]) {
            c = a[o.paramPlace], "?" == c[0] && (c = c.substring(1)), c = c.split("&");

            for (var f = 0; f < c.length; ++f) {
              var g = c[f].split("=", 2);
              2 == g.length && (l[g[0]] = decodeURIComponent(g[1].replace(/\+/g, " ")));
            }
          }

          return o.idPlace && (u = a[o.idPlace]), r = t.extend(!0, {}, o.params, s.opts[n], l), p = "function" === t.type(o.url) ? o.url.call(this, a, r, s) : e(o.url, a, r), i = "function" === t.type(o.thumb) ? o.thumb.call(this, a, r, s) : e(o.thumb, a), !1;
        }
      }), h ? (s.src = p, s.type = h, s.opts.thumb || s.opts.$thumb && s.opts.$thumb.length || (s.opts.thumb = i), u && (s.opts.id = d + "-" + u), "iframe" === h && (t.extend(!0, s.opts, {
        iframe: {
          preload: !1,
          scrolling: "no"
        },
        smallBtn: !1,
        closeBtn: !0,
        fullScreen: !1,
        slideShow: !1
      }), s.opts.slideClass += " fancybox-slide--video")) : s.type = "iframe");
    });
  });
}(window.jQuery), function (t, e, n) {
  "use strict";

  var o = function () {
    return t.requestAnimationFrame || t.webkitRequestAnimationFrame || t.mozRequestAnimationFrame || function (e) {
      t.setTimeout(e, 1e3 / 60);
    };
  }(),
      s = function s(e) {
    var n = [];
    e = e.originalEvent || e || t.e, e = e.touches && e.touches.length ? e.touches : e.changedTouches && e.changedTouches.length ? e.changedTouches : [e];

    for (var o in e) {
      e[o].pageX ? n.push({
        x: e[o].pageX,
        y: e[o].pageY
      }) : e[o].clientX && n.push({
        x: e[o].clientX,
        y: e[o].clientY
      });
    }

    return n;
  },
      i = function i(t, e, n) {
    return e && t ? "x" === n ? t.x - e.x : "y" === n ? t.y - e.y : Math.sqrt(Math.pow(t.x - e.x, 2) + Math.pow(t.y - e.y, 2)) : 0;
  },
      a = function a(t) {
    return t.is("a") || t.is("button") || t.is("input") || t.is("select") || t.is("textarea") || n.isFunction(t.get(0).onclick);
  },
      r = function r(e) {
    var n = t.getComputedStyle(e)["overflow-y"],
        o = t.getComputedStyle(e)["overflow-x"],
        s = ("scroll" === n || "auto" === n) && e.scrollHeight > e.clientHeight,
        i = ("scroll" === o || "auto" === o) && e.scrollWidth > e.clientWidth;
    return s || i;
  },
      c = function c(t) {
    for (var e = !1;;) {
      if (e = r(t.get(0))) break;
      if (t = t.parent(), !t.length || t.hasClass("fancybox-slider") || t.is("body")) break;
    }

    return e;
  },
      l = function l(t) {
    var e = this;
    e.instance = t, e.$wrap = t.$refs.slider_wrap, e.$slider = t.$refs.slider, e.$container = t.$refs.container, e.destroy(), e.$wrap.on("touchstart.fb mousedown.fb", n.proxy(e, "ontouchstart"));
  };

  l.prototype.destroy = function () {
    this.$wrap.off("touchstart.fb mousedown.fb touchmove.fb mousemove.fb touchend.fb touchcancel.fb mouseup.fb mouseleave.fb");
  }, l.prototype.ontouchstart = function (e) {
    var o = this,
        r = n(e.target),
        l = o.instance,
        u = l.current,
        d = u.$content || u.$placeholder;
    a(r) || a(r.parent()) || c(r) && !r.hasClass("fancybox-slide") || (e.stopPropagation(), e.preventDefault(), !u || o.instance.isAnimating || o.instance.isClosing || (o.startPoints = s(e), !o.startPoints || o.startPoints.length > 1 && !u.isMoved || (o.$wrap.off("touchmove.fb mousemove.fb", n.proxy(o, "ontouchmove")), o.$wrap.off("touchend.fb touchcancel.fb mouseup.fb mouseleave.fb", n.proxy(o, "ontouchend")), o.$wrap.on("touchmove.fb mousemove.fb", n.proxy(o, "ontouchmove")), o.$wrap.on("touchend.fb touchcancel.fb mouseup.fb mouseleave.fb", n.proxy(o, "ontouchend")), o.$target = r, o.$content = d, o.startTime = new Date().getTime(), o.distanceX = o.distanceY = o.distance = 0, o.canvasWidth = Math.round(u.$slide.width()), o.canvasHeight = Math.round(u.$slide.height()), o.canTap = !1, o.isPanning = !1, o.isSwiping = !1, o.isZooming = !1, o.sliderStartPos = n.fancybox.getTranslate(o.$slider), o.contentStartPos = n.fancybox.getTranslate(o.$content), o.contentLastPos = null, 1 !== o.startPoints.length || o.isZooming || (o.canTap = u.isMoved, "image" === u.type && (o.contentStartPos.width > o.canvasWidth + 1 || o.contentStartPos.height > o.canvasHeight + 1) ? (n.fancybox.stop(o.$content), o.isPanning = !0) : (n.fancybox.stop(o.$slider), o.isSwiping = !0), o.$container.addClass("fancybox-controls--isGrabbing")), 2 === o.startPoints.length && u.isMoved && !u.hasError && "image" === u.type && (u.isLoaded || u.$ghost) && (o.isZooming = !0, o.isSwiping = !1, o.isPanning = !1, n.fancybox.stop(o.$content), o.centerPointStartX = .5 * (o.startPoints[0].x + o.startPoints[1].x) - n(t).scrollLeft(), o.centerPointStartY = .5 * (o.startPoints[0].y + o.startPoints[1].y) - n(t).scrollTop(), o.percentageOfImageAtPinchPointX = (o.centerPointStartX - o.contentStartPos.left) / o.contentStartPos.width, o.percentageOfImageAtPinchPointY = (o.centerPointStartY - o.contentStartPos.top) / o.contentStartPos.height, o.startDistanceBetweenFingers = i(o.startPoints[0], o.startPoints[1])))));
  }, l.prototype.ontouchmove = function (t) {
    var e = this;
    t.preventDefault(), e.newPoints = s(t), e.newPoints && e.newPoints.length && (e.distanceX = i(e.newPoints[0], e.startPoints[0], "x"), e.distanceY = i(e.newPoints[0], e.startPoints[0], "y"), e.distance = i(e.newPoints[0], e.startPoints[0]), e.distance > 0 && (e.isSwiping ? e.onSwipe() : e.isPanning ? e.onPan() : e.isZooming && e.onZoom()));
  }, l.prototype.onSwipe = function () {
    var e,
        s = this,
        i = s.isSwiping,
        a = s.sliderStartPos.left;
    i === !0 ? Math.abs(s.distance) > 10 && (s.instance.group.length < 2 ? s.isSwiping = "y" : !s.instance.current.isMoved || s.instance.opts.touch.vertical === !1 || "auto" === s.instance.opts.touch.vertical && n(t).width() > 800 ? s.isSwiping = "x" : (e = Math.abs(180 * Math.atan2(s.distanceY, s.distanceX) / Math.PI), s.isSwiping = e > 45 && e < 135 ? "y" : "x"), s.canTap = !1, s.instance.current.isMoved = !1, s.startPoints = s.newPoints) : ("x" == i && (!s.instance.current.opts.loop && 0 === s.instance.current.index && s.distanceX > 0 ? a += Math.pow(s.distanceX, .8) : !s.instance.current.opts.loop && s.instance.current.index === s.instance.group.length - 1 && s.distanceX < 0 ? a -= Math.pow(-s.distanceX, .8) : a += s.distanceX), s.sliderLastPos = {
      top: "x" == i ? 0 : s.sliderStartPos.top + s.distanceY,
      left: a
    }, o(function () {
      n.fancybox.setTranslate(s.$slider, s.sliderLastPos);
    }));
  }, l.prototype.onPan = function () {
    var t,
        e,
        s,
        i = this;
    i.canTap = !1, t = i.contentStartPos.width > i.canvasWidth ? i.contentStartPos.left + i.distanceX : i.contentStartPos.left, e = i.contentStartPos.top + i.distanceY, s = i.limitMovement(t, e, i.contentStartPos.width, i.contentStartPos.height), s.scaleX = i.contentStartPos.scaleX, s.scaleY = i.contentStartPos.scaleY, i.contentLastPos = s, o(function () {
      n.fancybox.setTranslate(i.$content, i.contentLastPos);
    });
  }, l.prototype.limitMovement = function (t, e, n, o) {
    var s,
        i,
        a,
        r,
        c = this,
        l = c.canvasWidth,
        u = c.canvasHeight,
        d = c.contentStartPos.left,
        p = c.contentStartPos.top,
        h = c.distanceX,
        f = c.distanceY;
    return s = Math.max(0, .5 * l - .5 * n), i = Math.max(0, .5 * u - .5 * o), a = Math.min(l - n, .5 * l - .5 * n), r = Math.min(u - o, .5 * u - .5 * o), n > l && (h > 0 && t > s && (t = s - 1 + Math.pow(-s + d + h, .8) || 0), h < 0 && t < a && (t = a + 1 - Math.pow(a - d - h, .8) || 0)), o > u && (f > 0 && e > i && (e = i - 1 + Math.pow(-i + p + f, .8) || 0), f < 0 && e < r && (e = r + 1 - Math.pow(r - p - f, .8) || 0)), {
      top: e,
      left: t
    };
  }, l.prototype.limitPosition = function (t, e, n, o) {
    var s = this,
        i = s.canvasWidth,
        a = s.canvasHeight;
    return n > i ? (t = t > 0 ? 0 : t, t = t < i - n ? i - n : t) : t = Math.max(0, i / 2 - n / 2), o > a ? (e = e > 0 ? 0 : e, e = e < a - o ? a - o : e) : e = Math.max(0, a / 2 - o / 2), {
      top: e,
      left: t
    };
  }, l.prototype.onZoom = function () {
    var e = this,
        s = e.contentStartPos.width,
        a = e.contentStartPos.height,
        r = e.contentStartPos.left,
        c = e.contentStartPos.top,
        l = i(e.newPoints[0], e.newPoints[1]),
        u = l / e.startDistanceBetweenFingers,
        d = Math.floor(s * u),
        p = Math.floor(a * u),
        h = (s - d) * e.percentageOfImageAtPinchPointX,
        f = (a - p) * e.percentageOfImageAtPinchPointY,
        g = (e.newPoints[0].x + e.newPoints[1].x) / 2 - n(t).scrollLeft(),
        b = (e.newPoints[0].y + e.newPoints[1].y) / 2 - n(t).scrollTop(),
        m = g - e.centerPointStartX,
        y = b - e.centerPointStartY,
        v = r + (h + m),
        x = c + (f + y),
        w = {
      top: x,
      left: v,
      scaleX: e.contentStartPos.scaleX * u,
      scaleY: e.contentStartPos.scaleY * u
    };
    e.canTap = !1, e.newWidth = d, e.newHeight = p, e.contentLastPos = w, o(function () {
      n.fancybox.setTranslate(e.$content, e.contentLastPos);
    });
  }, l.prototype.ontouchend = function (t) {
    var e = this,
        o = e.instance.current,
        i = Math.max(new Date().getTime() - e.startTime, 1),
        a = e.isSwiping,
        r = e.isPanning,
        c = e.isZooming;
    return e.endPoints = s(t), e.$container.removeClass("fancybox-controls--isGrabbing"), e.$wrap.off("touchmove.fb mousemove.fb", n.proxy(this, "ontouchmove")), e.$wrap.off("touchend.fb touchcancel.fb mouseup.fb mouseleave.fb", n.proxy(this, "ontouchend")), e.isSwiping = !1, e.isPanning = !1, e.isZooming = !1, e.canTap ? e.ontap() : (e.velocityX = e.distanceX / i * .5, e.velocityY = e.distanceY / i * .5, e.speed = o.opts.speed, e.speedX = Math.max(.75 * e.speed, Math.min(1.5 * e.speed, 1 / Math.abs(e.velocityX) * e.speed)), e.speedY = Math.max(.75 * e.speed, Math.min(1.5 * e.speed, 1 / Math.abs(e.velocityY) * e.speed)), void (r ? e.endPanning() : c ? e.endZooming() : e.endSwiping(a)));
  }, l.prototype.endSwiping = function (t) {
    var e = this;
    "y" == t && Math.abs(e.distanceY) > 50 ? (n.fancybox.animate(e.$slider, null, {
      top: e.sliderStartPos.top + e.distanceY + 150 * e.velocityY,
      left: e.sliderStartPos.left,
      opacity: 0
    }, e.speedY), e.instance.close(!0)) : "x" == t && e.distanceX > 50 ? e.instance.previous(e.speedX) : "x" == t && e.distanceX < -50 ? e.instance.next(e.speedX) : e.instance.update(!1, !0, Math.abs(e.distance) * e.speed / 50);
  }, l.prototype.endPanning = function () {
    var t,
        e,
        o,
        s = this;
    s.contentLastPos && (t = s.contentLastPos.left + s.velocityX * s.speed * 2, e = s.contentLastPos.top + s.velocityY * s.speed * 2, o = s.limitPosition(t, e, s.contentStartPos.width, s.contentStartPos.height), o.width = s.contentStartPos.width, o.height = s.contentStartPos.height, n.fancybox.animate(s.$content, null, o, s.speed, "easeOutSine"));
  }, l.prototype.endZooming = function () {
    var t,
        e,
        o,
        s,
        i = this,
        a = i.instance.current,
        r = i.newWidth,
        c = i.newHeight;
    i.contentLastPos && (t = i.contentLastPos.left, e = i.contentLastPos.top, s = {
      top: e,
      left: t,
      width: r,
      height: c,
      scaleX: 1,
      scaleY: 1
    }, n.fancybox.setTranslate(i.$content, s), r < i.canvasWidth && c < i.canvasHeight ? i.instance.scaleToFit(150) : r > a.width || c > a.height ? i.instance.scaleToActual(i.centerPointStartX, i.centerPointStartY, 150) : (o = i.limitPosition(t, e, r, c), n.fancybox.animate(i.$content, null, o, i.speed, "easeOutSine")));
  }, l.prototype.ontap = function () {
    var t = this,
        e = t.instance,
        o = e.current,
        s = t.endPoints[0].x,
        i = t.endPoints[0].y;
    if (s -= t.$wrap.offset().left, i -= t.$wrap.offset().top, !n.fancybox.isTouch) return o.opts.closeClickOutside && t.$target.is(".fancybox-slide") ? void e.close() : void ("image" == o.type && o.isMoved && (e.canPan() ? e.scaleToFit() : e.isScaledDown() ? e.scaleToActual(s, i) : e.group.length < 2 && e.close()));

    if (t.tapped) {
      if (clearTimeout(t.tapped), t.tapped = null, Math.abs(s - t.x) > 50 || Math.abs(i - t.y) > 50 || !o.isMoved) return this;
      "image" == o.type && (o.isLoaded || o.$ghost) && (e.canPan() ? e.scaleToFit() : e.isScaledDown() && e.scaleToActual(s, i));
    } else t.x = s, t.y = i, t.tapped = setTimeout(function () {
      t.tapped = null, e.toggleControls(!0);
    }, 300);

    return this;
  }, n(e).on("onActivate.fb", function (t, e) {
    e.opts.touch && !e.Guestures && (e.Guestures = new l(e));
  }), n(e).on("beforeClose.fb", function (t, e) {
    e.Guestures && e.Guestures.destroy();
  });
}(window, document, window.jQuery), function (t, e) {
  "use strict";

  var n = function n(t) {
    this.instance = t, this.init();
  };

  e.extend(n.prototype, {
    timer: null,
    speed: 3e3,
    $button: null,
    init: function init() {
      var t = this;
      t.$button = e('<button data-fancybox-play class="fancybox-button fancybox-button--play" title="Slideshow (P)"></button>').appendTo(t.instance.$refs.buttons), t.instance.$refs.container.on("click", "[data-fancybox-play]", function () {
        t.toggle();
      });
    },
    set: function set() {
      var t = this;
      t.instance && t.instance.current && t.instance.currIndex < t.instance.group.length - 1 ? t.timer = setTimeout(function () {
        t.instance.next();
      }, t.speed) : t.stop();
    },
    clear: function clear() {
      var t = this;
      clearTimeout(t.timer), t.timer = null;
    },
    start: function start() {
      var t = this;
      t.stop(), t.instance && t.instance.current && t.instance.currIndex < t.instance.group.length - 1 && (t.instance.$refs.container.on({
        "beforeLoad.fb.player": e.proxy(t, "clear"),
        "onComplete.fb.player": e.proxy(t, "set")
      }), t.instance.current.isComplete ? t.set() : t.timer = !0, t.instance.$refs.container.trigger("onPlayStart"), t.$button.addClass("fancybox-button--pause"));
    },
    stop: function stop() {
      var t = this;
      t.clear(), t.instance.$refs.container.trigger("onPlayEnd").off(".player"), t.$button.removeClass("fancybox-button--pause");
    },
    toggle: function toggle() {
      var t = this;
      t.timer ? t.stop() : t.start();
    }
  }), e(t).on("onInit.fb", function (t, e) {
    e.opts.slideShow && !e.SlideShow && e.group.length > 1 && (e.SlideShow = new n(e));
  }), e(t).on("beforeClose.fb onDeactivate.fb", function (t, e) {
    e.SlideShow && e.SlideShow.stop();
  });
}(document, window.jQuery), function (t, e) {
  "use strict";

  var n = function n(t) {
    this.instance = t, this.init();
  };

  e.extend(n.prototype, {
    $button: null,
    init: function init() {
      var n = this;
      n.isAvailable() && (n.$button = e('<button data-fancybox-fullscreen class="fancybox-button fancybox-button--fullscreen" title="Full screen (F)"></button>').appendTo(n.instance.$refs.buttons), n.instance.$refs.container.on("click.fb-fullscreen", "[data-fancybox-fullscreen]", function (t) {
        t.stopPropagation(), t.preventDefault(), n.toggle();
      }), e(t).on("onUpdate.fb", function (t, e) {
        n.$button.toggle(!!e.current.opts.fullScreen), n.$button.toggleClass("fancybox-button-shrink", n.isActivated());
      }), e(t).on("afterClose.fb", function () {
        n.exit();
      }));
    },
    isAvailable: function isAvailable() {
      var t = this.instance.$refs.container.get(0);
      return !!(t.requestFullscreen || t.msRequestFullscreen || t.mozRequestFullScreen || t.webkitRequestFullscreen);
    },
    isActivated: function isActivated() {
      return !!(t.fullscreenElement || t.mozFullScreenElement || t.webkitFullscreenElement || t.msFullscreenElement);
    },
    launch: function launch() {
      var t = this.instance.$refs.container.get(0);
      t && !this.instance.isClosing && (t.requestFullscreen ? t.requestFullscreen() : t.msRequestFullscreen ? t.msRequestFullscreen() : t.mozRequestFullScreen ? t.mozRequestFullScreen() : t.webkitRequestFullscreen && t.webkitRequestFullscreen(t.ALLOW_KEYBOARD_INPUT));
    },
    exit: function exit() {
      t.exitFullscreen ? t.exitFullscreen() : t.msExitFullscreen ? t.msExitFullscreen() : t.mozCancelFullScreen ? t.mozCancelFullScreen() : t.webkitExitFullscreen && t.webkitExitFullscreen();
    },
    toggle: function toggle() {
      this.isActivated() ? this.exit() : this.isAvailable() && this.launch();
    }
  }), e(t).on("onInit.fb", function (t, e) {
    e.opts.fullScreen && !e.FullScreen && (e.FullScreen = new n(e));
  });
}(document, window.jQuery), function (t, e) {
  "use strict";

  var n = function n(t) {
    this.instance = t, this.init();
  };

  e.extend(n.prototype, {
    $button: null,
    $grid: null,
    $list: null,
    isVisible: !1,
    init: function init() {
      var t = this;
      t.$button = e('<button data-fancybox-thumbs class="fancybox-button fancybox-button--thumbs" title="Thumbnails (G)"></button>').appendTo(this.instance.$refs.buttons).on("touchend click", function (e) {
        e.stopPropagation(), e.preventDefault(), t.toggle();
      });
    },
    create: function create() {
      var t,
          n,
          o = this.instance;
      this.$grid = e('<div class="fancybox-thumbs"></div>').appendTo(o.$refs.container), t = "<ul>", e.each(o.group, function (e, o) {
        n = o.opts.thumb || (o.opts.$thumb ? o.opts.$thumb.attr("src") : null), n || "image" !== o.type || (n = o.src), n && n.length && (t += '<li data-index="' + e + '"  tabindex="0" class="fancybox-thumbs-loading"><img data-src="' + n + '" /></li>');
      }), t += "</ul>", this.$list = e(t).appendTo(this.$grid).on("click touchstart", "li", function () {
        o.jumpTo(e(this).data("index"));
      }), this.$list.find("img").hide().one("load", function () {
        var t,
            n,
            o,
            s,
            i = e(this).parent().removeClass("fancybox-thumbs-loading"),
            a = i.outerWidth(),
            r = i.outerHeight();
        t = this.naturalWidth || this.width, n = this.naturalHeight || this.height, o = t / a, s = n / r, o >= 1 && s >= 1 && (o > s ? (t /= s, n = r) : (t = a, n /= o)), e(this).css({
          width: Math.floor(t),
          height: Math.floor(n),
          "margin-top": Math.min(0, Math.floor(.3 * r - .3 * n)),
          "margin-left": Math.min(0, Math.floor(.5 * a - .5 * t))
        }).show();
      }).each(function () {
        this.src = e(this).data("src");
      });
    },
    focus: function focus() {
      this.instance.current && this.$list.children().removeClass("fancybox-thumbs-active").filter('[data-index="' + this.instance.current.index + '"]').addClass("fancybox-thumbs-active").focus();
    },
    close: function close() {
      this.$grid.hide();
    },
    update: function update() {
      this.instance.$refs.container.toggleClass("fancybox-container--thumbs", this.isVisible), this.isVisible ? (this.$grid || this.create(), this.$grid.show(), this.focus()) : this.$grid && this.$grid.hide(), this.instance.update();
    },
    hide: function hide() {
      this.isVisible = !1, this.update();
    },
    show: function show() {
      this.isVisible = !0, this.update();
    },
    toggle: function toggle() {
      this.isVisible ? this.hide() : this.show();
    }
  }), e(t).on("onInit.fb", function (t, e) {
    e.opts.thumbs && !e.Thumbs && e.group.length > 1 && ("image" == e.group[0].type || e.group[0].opts.thumb) && ("image" == e.group[1].type || e.group[1].opts.thumb) && (e.Thumbs = new n(e));
  }), e(t).on("beforeMove.fb", function (t, e, n) {
    var o = e.Thumbs;
    o && (n.modal ? (o.$button.hide(), o.hide()) : (e.opts.thumbs.showOnStart === !0 && e.firstRun && o.show(), o.$button.show(), o.isVisible && o.focus()));
  }), e(t).on("beforeClose.fb", function (t, e) {
    e.Thumbs && e.Thumbs.isVisible && e.opts.thumbs.hideOnClosing !== !1 && e.Thumbs.close(), e.Thumbs = null;
  });
}(document, window.jQuery);