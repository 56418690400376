////////////////////////////////////////////////////////////////////////////////////////////////////////////
///// MOBILE MENU NAVIGATION
////////////////////////////////////////////////////////////////////////////////////////////////////////////
var mobileMenu = function () {
  "use strict"; // Private Properties

  var pageUrlPath = window.location.pathname;
  var selectedClassName = 'selected'; // the selected class name for menu items

  var selectedParentClassName = 'selected-parent'; // the selected class name for menu items

  var openStatusClassName = 'open';
  var didUserScroll;
  var lastScrollTop = 0;
  var minScrollBeforeAction = 5; // pixels

  var navbarHeight = $('header').outerHeight(); // Private Methods

  function init() {
    if ($('nav.mobile').length > 0) {
      //watchHamburgerClick();
      // Select the nav item and add arrow div to each has-children link
      selectSectionNavItem();
      $('.mobileNavWrapper input[type="text"]').attr("placeholder", "Search");
    }

    hideHeaderOnScrollInit();
  }

  $(document).on("click", '.hamburger', function () {
    console.log("hamburger clicked");

    if ($('nav.mobile').hasClass(openStatusClassName)) {
      closeMenu();
    } else {
      openMenu();
    }
  });

  function onResize() {
    if ($('nav.mobile').length > 0 && window.innerWidth > 1024) {
      closeMenu();
    }
  }

  function selectSectionNavItem() {
    pageUrlPath = pageUrlPath === '/' ? '/home' : '/';
    $('nav.mobile').children().find('a[href$="' + pageUrlPath + '"], a[href$="' + pageUrlPath + '/"]').last().each(function () {
      findParentNavItem(this);
    });
  }

  function findParentNavItem(anchorElem) {
    if (anchorElem.href.indexOf(pageUrlPath) !== -1 && pageUrlPath !== '/') {
      // found the menu item
      $(anchorElem).parent().addClass(selectedClassName);
      highLightParentNavItem($(anchorElem).parent());
    }
  }

  function highLightParentNavItem(anchorElem) {
    var elem = $(anchorElem).closest('.hasChildren'); // find the closest previous .hasChildren and the anchor in that li and highlight it

    if (elem.length > 0 && !elem.hasClass(selectedParentClassName)) {
      elem.addClass(selectedParentClassName + ' ' + openStatusClassName);
      highLightParentNavItem(elem); // recursively call this function
    } else if (elem.length > 0) {
      // highlight the main nav item
      elem.parent().parent().closest('.hasChildren').addClass(selectedParentClassName); // needed to jump out of the 
    }
  }

  function openMenu() {
    var navContent = $('header nav').html();
    $('.hamburger').addClass(openStatusClassName);
    $('nav.mobile').show();
    setTimeout(function () {
      $('nav.mobile').addClass(openStatusClassName);
      $('.pageWrapper, .mobileHeader').addClass('withNavOpen');
    }, 100);
  }

  function closeMenu() {
    $('.hamburger').removeClass(openStatusClassName);
    $('nav.mobile').removeClass(openStatusClassName);
    $('.pageWrapper, .mobileHeader').removeClass('withNavOpen');
    setTimeout(function () {
      $('nav.mobile').hide();
    }, 200);
  }

  function hideHeaderOnScrollInit() {
    setInterval(function () {
      if (didUserScroll) {
        userDidScroll();
        didUserScroll = false;
      }
    }, 250);
  }

  function onUserScroll() {
    didUserScroll = true;
  }

  function userDidScroll() {
    var scrollTopLocation = $(window).scrollTop(); // Make sure they scroll more than minScrollBeforeAction

    if (Math.abs(lastScrollTop - scrollTopLocation) <= minScrollBeforeAction) return; // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.

    if (scrollTopLocation > lastScrollTop && scrollTopLocation > navbarHeight) {
      // Scroll Down
      if (!$('nav.mobile').hasClass('open')) {
        // If mobile nav is not open
        $('.mobileHeader').removeClass('nav-down').addClass('nav-up');
      }
    } else {
      // Scroll Up
      if (scrollTopLocation + $(window).height() < $(document).height()) {
        $('.mobileHeader').removeClass('nav-up').addClass('nav-down');
      }
    }

    lastScrollTop = scrollTopLocation;
  } // Public Methods


  return {
    Init: function Init() {
      init();
    },
    OnResize: function OnResize() {
      onResize();
    },
    OnUserScroll: function OnUserScroll() {
      onUserScroll(); // Hide/Show the fixed header https://codepen.io/vivinantony/pen/XXWoQx
    }
  };
}(); ////////////////////////////////////////////////////////////////////////////////////////////////////////////
///// INITIALIZE THE MOBILE MENU
////////////////////////////////////////////////////////////////////////////////////////////////////////////


mobileMenu.Init();
$(window).on('resize', function () {
  mobileMenu.OnResize();
});
$(window).scroll(function () {
  mobileMenu.OnUserScroll();
});