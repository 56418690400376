/*
 * MAIN SCRIPTS ENTRY FILE
 */
// Dependencies
require('slick-carousel');

require('jquery-lazy');

require('jquery-backstretch');

require("waypoints/lib/jquery.waypoints.js"); // ToDo: revisit. it makes sense that we need the jquery build ... but not sure if the resolve alias is required in webpack.config.


require("waypoints/lib/shortcuts/sticky.js"); // Old bundle files which have not been changes to npm dependencies:
//require("./src/ThirdParty/svg-inject.js");


require("./src/ThirdParty/jquery.fancybox.min.js"); // installed but not imported yet -- need to figure out CSS. For now still using local copy
// MAIN SCRIPTS


require("./src/master.js");

require("./src/mobile-menu.js");